@import url("https://fonts.googleapis.com/css?family=Lato:300,400,500,700")

@import "variables"
@import "reset"

html,body
    font-family 'Lato'
    background-color white
    font-weight 300
    overflow hidden
    height 100%
    width 100%

*
    box-sizing border-box

.unselectable
    -webkit-user-select none
    -moz-user-select none
    -ms-user-select none
    -o-user-select none
    user-select none

.selectable
    -webkit-user-select all
    -moz-user-select all
    -ms-user-select all
    -o-user-select all
    user-select all

:focus
    outline none

a
    color inherit
    text-decoration none
    &:hover
        color inherit
        text-decoration none
    &:link
        color inherit
        text-decoration none
    &:visited
        color inherit
        text-decoration none

[v-cloak]
	display: none !important;

.header
    width 100%
    height 174px
    position relative
    z-index 99999
    box-shadow 0 0 3px #000
    box-shadow 0 0 3px rgba(0,0,0,0.5)
    .nav
        position absolute
        left 376px
        height 72px
        top 27px
        span
            &.nav-item
                display inline-block
                margin 8px
                padding 16px
                font-size 1.5em
                height 56px
                line-height 24px
                cursor pointer
                transition color linear 0.1s
                font-weight 400
    span
        &.nav-item
            &:hover
                color #e4b462
    .social
        float right
        height 100px
        padding 13px
        a
            display block
            float right
            cursor pointer
            background-color white
            img
                display block
                float right
                width 48px
                height 48px
                margin 4px
    .menu
        height 48px
        position relative
        overflow visible
        display block
        .buttons
            height 48px
            float left
            .button
                float left
                height 48px
                cursor pointer
                transition all linear 0.1s
                &:hover
                    box-shadow inset 0 -3px #e4b462
                span
                    display block
                    float left
                    height 48px
                    line-height 48px
                    padding-right 8px
                    font-size 0.8em
                    text-transform uppercase
                    letter-spacing 1px
                    font-weight bold
                img
                    width 32px
                    height 48px
                    padding 16px 8px
                    display block
                    float left
    .nav-overview
        float right
        height 40px
        width 275px
        border 1px solid #e4b462
        border-radius 3px
        margin 0 8px 8px 0
        overflow hidden
        img
            &.show-overview
                padding 8px
                display block
                float left
                width 40px
                height 40px
                cursor pointer
        span
            display block
            float left
            margin 0
            padding 0 8px
            font-size 0.9em
            height 40px
            line-height 40px
            &.items
                border-left 1px solid #e4b462
        .acc
            float right
    .currentboard
        overflow hidden
        text-align center
        line-height 48px
        max-height 48px
        font-size 0.8em
        text-transform uppercase
        letter-spacing 1px
        font-weight bold
        padding 0 8px
        color #4f4f4f

.top-bar
    height 126px
    padding 13px
    position relative

.select
    position fixed
    z-index 4
    top 174px
    bottom 0
    height auto
    background-color white
    transition left linear 0.1s
    width 280px
    overflow hidden
    .top
        width 100%
        display block
        position relative
        background-color #4e4e4e
        height 32px
        form
            &.search
                float left
                width 24px
                margin 4px
                background-color #4e4e4e
                height 24px
                border-radius 999px
                overflow hidden
                transition all linear 0.1s
                &.full
                    width 224px
                    background-color #1f1f1f
                img
                    float left
                    padding 4px
                    width 24px
                    height 24px
                    cursor pointer
                input
                    width 200px
                    height 24px
                    border none
                    background-color inherit
                    color white
                    padding 0 8px 0 2px
                    font-family inherit
                    font-size 15px
    .custom
        height 32px
        background #333
        button
            display block
            font-family inherit
            font-size inherit
            color white
            background inherit
            height 32px
            width 100%
            border none
            cursor pointer
    &.sel-opt
        left -281px
        &.show
            left 0
            box-shadow 0 0 3px #000
            box-shadow 0 0 3px rgba(0, 0, 0, 0.5)
        .opts
            position absolute
            left 0
            top 32px
            bottom 0
            right 0
            overflow auto
            .opt
                margin-bottom 16px
        .opt
            h1
                padding 8px
                font-size 1em
                font-weight 300
                text-transform uppercase
                border-bottom 1px solid #ccc
            .options-list
                &.boards
                    .option
                        display block
                        padding 8px 8px 8px 16px
                        transition all linear 0.1s
                        cursor pointer
                        &.selected
                            background-color #727272
                            color white
                        &:hover
                            box-shadow inset 3px 0 #e4b461
                            padding 8px 8px 8px 19px
                            background-color #4e4e4e
                            color white
                &.color
                    .option
                        display block
                        clear both
                        cursor pointer
                        height 48px
                        transition all linear 0.1s
                        &:hover
                            box-shadow inset 3px 0 #e4b461
                            background-color #4e4e4e
                            color white
                        &.selected
                            background-color #727272
                            color white
                        span
                            float left
                            display block
                            width 200px
                            height 48px
                            line-height 48px
                            padding 0 16px
                        .color
                            display block
                            width 32px
                            height 32px
                            float right
                            margin 8px
                            cursor pointer
                            border 1px solid #333
        .single-opt
            margin 8px 0
            padding 16px
            display block
            border-top 1px solid #ccc
            border-bottom 1px solid #ccc
            cursor pointer
            &:hover
                background-color #727272
                border-color #727272
                color white
    &.sel-add
        left -281px
        &.show
            left 0
            box-shadow 0 0 3px #000
            box-shadow 0 0 3px rgba(0, 0, 0, 0.5)

.select .top button.back, .top span.close
    float right
    height 24px
    width 24px
    margin 4px
    border 2px solid #fff
    color white
    border-radius 99px
    background-color inherit
    font-weight 500
    font-size 1em
    cursor pointer
    background-color #4e4e4e
    background-image url(../img/close_white.svg)
    background-size 16px 16px
    background-position center

.select .top button.back:hover, .top span.close:hover
    background-color #fff
    background-image url(../img/close.svg)

body
    .select
        &.sel-add
            .items
                position absolute
                top 64px
                bottom 0
                left 0
                right 0
                overflow auto
                .group
                    display block
                    overflow hidden
                    .brand-name
                        display block
                        height 32px
                        padding 0 8px
                        line-height 32px
                        font-size 1em
                        text-transform uppercase
                        cursor pointer
                        background-color #f0f0f0
                        border-bottom 1px solid #ccc
                        transition all linear 0.1s
                        &:hover
                            border-bottom 1px solid #e4b462
                    &.dropdown
                        .brand-name
                            border-bottom 1px solid #e4b462
                .group-items
                    display block
                    border-left 2px solid #4e4e4e
                    .item
                        display block
                        border-bottom 1px solid #ccc
                        cursor default
                        clear both
                        position relative
                        padding-right 32px
                        &.find-true
                            background-color blue
                        span
                            display block
                            line-height 32px
                            padding-left 24px
                        img
                            &.item-img
                                display none
                            &.add-img
                                height 32px
                                width 32px
                                padding 8px
                                display block
                                position absolute
                                right 0
                                top 0
                                opacity 0
                                transition opacity 0.05s linear
                        &:hover
                            box-shadow -2px 0 #e4b462
                            background-color #f7f7f7
                            img
                                &.add-img
                                    opacity 1
                                    cursor pointer

.overview
    display none
    position fixed
    z-index 99999
    left 0
    top 0
    bottom 0
    right 0
    padding 32px
    background-color #ffffff
    background-color rgba(255, 255, 255, 0.56)
    &.show
        display block
    .overview-content
        display block
        width 100%
        height 100%
        background-color white
        box-shadow 0 0 8px #000000
        box-shadow 0 0 8px rgba(0,0,0,0.35)
        position relative
    .top
        display block
        height 32px
        background-color #4e4e4e
        color white
        h1
            display block
            float left
            padding 0 16px
            line-height 30px
            font-size 1.4em
            text-transform lowercase
    .view
        position absolute
        left 0
        right 0
        bottom 0
        top 32px
        overflow auto
        .item
            margin 8px
            clear both
            .sku
                float left
                display block
                width 125px
            &.board
                .board
                    padding 8px
                    border-radius 3px
                    border 1px solid #ccc
            &.pedal
                .pedal
                    float left
                    line-height 24px
                    padding-left 2px
                .util
                    float left
                    line-height 24px
                    padding-left 8px

.editor
    background-color #e6e4e1
    overflow hidden
    position absolute
    left 0
    right 0
    top 174px
    bottom 0
    .loading-screen
        position absolute
        left 0
        right 0
        bottom 0
        top 0
        background-color #e6e4e1
        img
            display block
            position absolute
            left 50%
            top 50%
            margin-top -8px
            margin-left -8px
    canvas
        cursor default
        &.active
            cursor pointer
    .obj-info
        display none
        position absolute
        left 10px
        top 10px
        width 100px
        pointer-events none
        & > *
            pointer-events all
        .close
            float left
            width 24px
            height 24px
            border-radius 99px
            cursor pointer
            background-color #000000
            background-color rgba(0, 0, 0, 0.65)
            background-image url(../img/close_white.svg)
            background-size 16px 16px
            background-repeat no-repeat
            background-position center center
            margin-right 2px
        .rotate
            float left
            width 24px
            height 24px
            border-radius 99px
            cursor pointer
            background-color #000000
            background-color rgba(0, 0, 0, 0.65)
            background-image url(../img/rotate_white.svg)
            background-size 16px 16px
            background-repeat no-repeat
            background-position center center
        .info
            float right
            width 24px
            height 24px
            border-radius 99px
            cursor pointer
            background-color #000000
            background-color rgba(0, 0, 0, 0.65)
            background-image url(../img/info.svg)
            background-size 16px 16px
            background-repeat no-repeat
            background-position center center
            display none
        div
            &:hover
                background-color #000000
                background-color rgba(0, 0, 0, 0.9)
    .zoom
        position absolute
        right 0
        z-index 999
        top 50%
        background-color white
        box-shadow 0 0 2px #000000
        box-shadow 0 0 2px rgba(0,0,0,0.5)
        margin-top -72px
        img
            display block
            width 48px
            padding 8px
            cursor pointer
            &:hover
                background-color #fff
                filter invert(100%)
                -webkit-filter invert(100%)
                padding 4px

.custom-pedal
    display flex
    align-items center
    justify-content center
    position fixed
    z-index 9000
    left 0
    top 174px
    bottom 0
    right 0
    background-color #ffffff
    background-color rgba(255, 255, 255, 0.56)

    .field
        margin-bottom 10px
        .field-name
            margin-bottom 5px

    .content
        max-width 640px
        max-height 100%
        overflow auto
        background-color #fff
        box-shadow 0 0 3px #000000
        box-shadow 0 0 3px rgba(0,0,0,0.5)
    .top
        width 100%
        display flex
        height 32px
        background-color #4e4e4e
        color white
        h1
            display block
            line-height 32px
            padding 0 16px
            max-height 32px
            flex 1
    .view
        display block
        padding 8px
        width 100%
        overflow: auto

.cf:before,
.cf:after
    content " "
    display table

.cf
    zoom 1
    &:after
        clear both
				

@media screen and (max-width: 950px)
    .header
        .nav-overview
            width 40px
            span
                &.items
                    border-left none
        .social
            float right
            height 100px
            padding 8px
            width 80px
            a
                display block
                float right
                cursor pointer
                background-color white
                img
                    display block
                    float right
                    width 24px
                    height 24px
                    margin 4px

@media screen and (max-width: 750px)
	.header
		height 120px
		.top-bar
			height 72px
			padding 0 13px;
			img
				height 50px
				margin-top 13px;
		.nav
			height 72px
			left 192px
			top 0
		.social
			height: 72px;
			padding: 4px;
	.menu
		.currentboard
			display none
	.select
		top 121px
	.custom-pedal
		top 121px
	.editor
		top 121px

		.obj-info
			.close, .rotate
				width: 32px
				height 32px
				background-size 24px 24px
	
		.zoom
			top auto
			bottom 0
			right auto
			margin-top 0;
			margin-left -72px
			left 50%

			img
				float left


.header .nav,
    .header .nav-min,
    .header .social,
    .header .menu,
    .select,
    .editor
		@media print
			display none !important

@media print
    .header
        height 127px
    .overview
        position fixed
        display block
        top 128px
        left 0
        right 0
        bottom 0
        background-color green
        padding 0
